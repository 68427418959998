/* eslint  react/forbid-prop-types: 0 */
import React from 'react';
import PropTypes from 'prop-types';
import SoundDetail from '../components/SoundDetail';
import Header from '../components/Header';
import { headData } from '../data/data';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../style/main.scss';

const soundDetail = ({ pageContext }) => {
  const { project } = pageContext;
  const { title, lang, keyword, siteUrl } = headData;
  const { img, slug, publishDate } = project;

  const titleText = `${project.title} | ${title}`;

  let description;
  if (project.info2 && project.info3) {
    description = `${project.info} ${project.info2} ${project.info3}`;
  } else if (project.info2) {
    description = `${project.info} ${project.info2}`;
  } else {
    description = project.info;
  }

  return (
    <>
      <Header
        title={title}
        titleText={titleText}
        lang={lang}
        description={description}
        keyword={keyword}
        baseUrl={siteUrl}
        siteUrl={`${siteUrl}/${slug}`}
        ogpUrl={img}
        createdAt={publishDate}
        updatedAt={publishDate}
        isBlogPost="true"
      />
      <SoundDetail project={project} />
    </>
  );
};

soundDetail.propTypes = {
  pageContext: PropTypes.shape({
    project: PropTypes.object,
  }),
};

export default soundDetail;
