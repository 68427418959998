import React from 'react';
import PropTypes from 'prop-types';

const OtherSoundList = ({ otherSoundList }) => {
  return (
    <>
      <h3>こちらの音楽もおすすめです</h3>
      {otherSoundList.map((otherSound) => (
        <p key={otherSound.slug}>
          <a href={`/sound/${otherSound.slug}/`}>{otherSound.title}</a>
        </p>
      ))}
    </>
  );
};

OtherSoundList.propTypes = {
  otherSoundList: PropTypes.arrayOf(PropTypes.any),
};

export default OtherSoundList;
