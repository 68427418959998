import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

const Header = ({
  title,
  titleText,
  lang,
  description,
  keyword,
  baseUrl,
  siteUrl,
  ogpUrl,
  isBlogPost,
  createdAt,
  updatedAt,
}) => {
  const data = useStaticQuery(
    graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 1366) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `
  );

  const image = data.images.edges.find((n) => n.node.relativePath.includes(ogpUrl));
  const imageSrc = image.node.childImageSharp.fluid.src;

  let jsonLdData;

  if (isBlogPost) {
    // profile image
    const profileImageName = 'profile.png';
    const profileImage = data.images.edges.find((n) =>
      n.node.relativePath.includes(profileImageName)
    );

    // ogp image
    const siteOgpImageName = 'ogp.png';
    const siteOgpImage = data.images.edges.find((n) =>
      n.node.relativePath.includes(siteOgpImageName)
    );

    const author = 'Yuki Shindo';

    const publisher = {
      '@type': 'Organization',
      name: author,
      logo: {
        '@type': 'ImageObject',
        url: siteOgpImage,
        height: 150,
        width: 150,
      },
    };

    const authorData = {
      '@type': 'Person',
      name: author,
      image: profileImage,
    };

    jsonLdData = {
      '@context': 'http://schema.org',
      '@type': 'BlogPosting',
      mainEntityOfPage: siteUrl,
      headline: titleText || title,
      image: imageSrc,
      editor: author,
      url: siteUrl,
      datePublished: createdAt,
      dateCreated: createdAt,
      dateModified: updatedAt,
      description,
      author: authorData,
      publisher,
    };
  }

  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{titleText || title}</title>
      <html lang={lang || 'en'} />
      <meta name="title" content={titleText || title} />
      <meta name="description" content={description} />
      <meta name="keywords" content={keyword} />
      {/* facebook */}
      <meta property="og:site_name" content={title} />
      <meta property="og:title" content={titleText || title} />
      <meta property="og:description" content={description} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:image" content={imageSrc} />
      {/* twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={siteUrl} />
      <meta name="twitter:title" content={titleText || title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={`${baseUrl}${imageSrc}`} />
      {isBlogPost && <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>}
    </Helmet>
  );
};

Header.propTypes = {
  title: PropTypes.string,
  titleText: PropTypes.string,
  lang: PropTypes.string,
  description: PropTypes.string,
  keyword: PropTypes.string,
  baseUrl: PropTypes.string,
  siteUrl: PropTypes.string,
  ogpUrl: PropTypes.string,
  isBlogPost: PropTypes.string,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
};

export default Header;
