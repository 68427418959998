import React from 'react';
import Title from '../Title/Title';

const LicenceDescription = () => (
  <>
    <Title title="商用利用可能な音楽" />
    <div className="mb-8" style={{ textAlign: 'left' }}>
      <h3 style={{ borderBottom: 'solid 1px #00cdac', marginBottom: 16 }}>
        無料・クレジット表記なしでご利用いただけます
      </h3>
      <p className="mb-4">
        このサイトで扱っている音楽・効果音はYouTubeなどを始めとする動画制作や、個人ゲーム開発などで商用利用することが可能です。
      </p>
      <p className="mb-4">これらの音楽は無料で利用できます。また、ライセンス表記も不要です。</p>
      <p className="mb-4">
        詳しい利用ルールについては
        <a href="/terms" target="_blank">
          利用規約
        </a>
        と
        <a href="/faq" target="_blank">
          よくあるご質問
        </a>
        をご確認ください。
      </p>
      <h3 style={{ borderBottom: 'solid 1px #00cdac', marginBottom: 16 }}>
        音楽のダウンロード方法
      </h3>
      <p className="mb-4">音楽をダウンロードする場合は、リンク先のBandcampのページから行います。</p>
      <p className="mb-4">
        Bandcampのページにある無料ダウンロードというボタンを押すことでダウンロードが可能です。
      </p>
      <p className="mb-4">また、曲名にカーソルを重ねることで、１曲単位のダウンロードも可能です。</p>
    </div>
  </>
);
export default LicenceDescription;
