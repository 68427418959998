import React, { useContext } from 'react';
import Tilt from 'react-tilt';
import { Container, Row, Col } from 'react-bootstrap';
import PortfolioContext from '../../context/context';
import ProjectImg from '../Image/ProjectImg';
import YoutubeLink from './YoutubeLink';
import BandcampLink from './BandcampLink';
import OfficialSiteLink from './OfficialSiteLink';
import LicenceDescription from './LicenceDescription';
import TagLinkList from './TagLinkList';
import OtherSoundList from './OtherSoundList';

const Projects = () => {
  const { projects } = useContext(PortfolioContext);

  return (
    <section id="projects">
      <Container>
        <div className="project-wrapper">
          {projects.map((project) => {
            const {
              title,
              info,
              info2,
              info3,
              youtubeUrl,
              bandcampUrl,
              officialSiteUrl,
              officialSiteLinkText,
              img,
              id,
              tags,
              otherSoundList,
            } = project;

            return (
              <Row key={id}>
                <Col lg={4} sm={12}>
                  <div className="project-wrapper__text">
                    <h2 className="project-wrapper__text-title">{title || 'Project Title'}</h2>
                    <div>
                      <p>
                        {info ||
                          'Lorem ipsum dolor sit, amet consectetur adipisicing elit. Excepturi neque, ipsa animi maiores repellendu distinctioaperiam earum dolor voluptatum consequatur blanditiis inventore debitis fuga numquam voluptate architecto itaque molestiae.'}
                      </p>
                      <p className="mb-4">{info2 || ''}</p>
                      <p className="mb-4">{info3 || ''}</p>
                    </div>
                    {youtubeUrl && <YoutubeLink youtubeUrl={youtubeUrl} />}
                    {bandcampUrl && <BandcampLink bandcampUrl={bandcampUrl} />}
                    {officialSiteUrl && officialSiteLinkText && (
                      <OfficialSiteLink
                        officialSiteUrl={officialSiteUrl}
                        linkText={officialSiteLinkText}
                      />
                    )}
                    {tags && !!tags.length && <TagLinkList tags={tags} />}
                    {otherSoundList && !!otherSoundList.length && (
                      <OtherSoundList otherSoundList={otherSoundList} />
                    )}
                  </div>
                </Col>
                <Col lg={8} sm={12}>
                  <div className="project-wrapper__image">
                    <Tilt
                      options={{
                        reverse: false,
                        max: 8,
                        perspective: 1000,
                        scale: 1,
                        speed: 500,
                        transition: true,
                        axis: null,
                        reset: true,
                        easing: 'cubic-bezier(.03,.98,.52,.99)',
                      }}
                    >
                      <div data-tilt className="thumbnail rounded">
                        <ProjectImg alt={title} filename={img} />
                      </div>
                    </Tilt>
                  </div>
                </Col>
              </Row>
            );
          })}
          <LicenceDescription />
        </div>
      </Container>
    </section>
  );
};

export default Projects;
