/* eslint  react/forbid-prop-types: 0 */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Hero from './Hero/Hero2';
import Project from './Projects/Project';
import Footer from './Footer/Footer';

import { PortfolioProvider } from '../context/context';

import { heroData, footerData } from '../data/data';

function SoundDetail({ project }) {
  const [hero, setHero] = useState({});
  const [projects, setProjects] = useState([]);
  const [footer, setFooter] = useState({});

  useEffect(() => {
    setHero({ ...heroData });
    setProjects([project]);
    setFooter({ ...footerData });
  }, []);

  return (
    <PortfolioProvider value={{ hero, projects, footer }}>
      <Hero />
      <Project />
      <Footer />
    </PortfolioProvider>
  );
}

SoundDetail.propTypes = {
  project: PropTypes.object,
};

export default SoundDetail;
