import React from 'react';
import PropTypes from 'prop-types';

const YoutubeLink = ({ youtubeUrl }) => (
  <div className="mb-4">
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="cta-btn cta-btn--hero"
      href={youtubeUrl}
    >
      実際の利用例を見る
      <br />
      (YouTubeに移動します)
    </a>
  </div>
);

YoutubeLink.propTypes = {
  youtubeUrl: PropTypes.string,
};

export default YoutubeLink;
