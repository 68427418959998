import React from 'react';
import PropTypes from 'prop-types';
import { getTagUrl } from '../../utils/tags';

const TagLinkList = ({ tags }) => {
  return (
    <>
      <h3>この音楽につけられたタグ</h3>
      {tags.map((tag) => (
        <p key={tag}>
          <a href={`/tags/${getTagUrl(tag)}/`}>{tag}</a>
        </p>
      ))}
    </>
  );
};

TagLinkList.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
};

export default TagLinkList;
