import React, { useContext } from 'react';
import { Container } from 'react-bootstrap';
import PortfolioContext from '../../context/context';

const Header = () => {
  const { hero } = useContext(PortfolioContext);
  const { title, subTitle } = hero;

  return (
    <section id="hero" className="jumbotron" style={{ minHeight: '60vh', height: 60 }}>
      <Container>
        <h1 className="hero-title">
          {title}
          <br />
          <span className="text-color-main">{subTitle}</span>
        </h1>
        <p className="hero-cta">
          <span className="cta-btn cta-btn--hero">
            <a href="/">TOPに戻る</a>
          </span>
        </p>
      </Container>
    </section>
  );
};

export default Header;
