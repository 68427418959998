import React from 'react';
import PropTypes from 'prop-types';

const BandcampLink = ({ bandcampUrl }) => (
  <div className="mb-4">
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="cta-btn cta-btn--hero"
      href={bandcampUrl}
    >
      ダウンロード
      <br />
      (Bandcampに移動します)
    </a>
  </div>
);

BandcampLink.propTypes = {
  bandcampUrl: PropTypes.string,
};

export default BandcampLink;
