import React from 'react';
import PropTypes from 'prop-types';

const OfficialSiteLink = ({ officialSiteUrl, linkText }) => (
  <div className="mb-4">
    <a
      target="_blank"
      rel="noopener noreferrer"
      className="cta-btn cta-btn--hero"
      href={officialSiteUrl}
    >
      {linkText}
    </a>
  </div>
);

OfficialSiteLink.propTypes = {
  officialSiteUrl: PropTypes.string,
  linkText: PropTypes.string,
};

export default OfficialSiteLink;
